<template>
    <div v-if="terms?.content">
        <p v-html="terms?.content" class="page-text page-text--sm grey--text"></p>
    </div>
</template>

<script>
import api from "@/api";
export default {
    props: {
        code: { type: String, default: "tos" },
    },
    data: () => ({
        terms: null,
    }),
    mounted() {
        this.search();
    },
    methods: {
        async search() {
            const { code } = this;
            const { terms } = await api.v1.terms.get({ code });
            this.terms = terms;
        },
    },
};
</script>

<style scoped>
>>>table, >>>table tr th, >>>table tr td{
    border: 1px solid #ddd;
}
>>>table tr th, >>>table tr td{
    padding: 6px;
}
>>>p{
    line-height: 1.6;
}
</style>
