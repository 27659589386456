<template>
    <v-row class="mx-n10 my-0">
        <v-col cols="3" md="2" class="pa-10">
            <span class="font-weight-medium">{{ title }}</span>
        </v-col>
        <v-col cols="9" md="10" class="pa-10">
            <span class="text-pre-wrap grey--text">{{ desc }}</span>
        </v-col>
    </v-row>
</template>

<script>
export default {
    props: {
        title: { type: String, default: "" },
        desc: { type: String, default: "" },
    },
};
</script>

<style>
</style>