<template>
    <client-page>
        <sub-visual sh="소개" tabActive="오시는 길" text="금산군에서 나의 자리를 찾다. 금산군 농촌신활력플러스사업을 소개합니다." image="/images/about/visual.jpg" />

        <page-section class="page-section--first">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3223.376109372455!2d127.47782228905929!3d36.108693735355885!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x356559cccac4f217%3A0x58de25ebc1ea4380!2z7Lap7LKt64Ko64-EIOq4iOyCsOq1sCDquIjsgrDsnY0g67mE64uo66GcIDM4Mg!5e0!3m2!1sko!2skr!4v1729647338528!5m2!1sko!2skr" width="100%" style="border: 0" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" class="map" />
        </page-section>

        <page-section class="pt-0 page-section--last">
            <v-divider class="v-divider--dark" />
            <div class="py-30 py-md-40">
                <v-row no-gutters class="page-text page-text--lg">
                    <v-col cols="auto">
                        <v-sheet width="140" class="d-flex align-center line-height-15 pb-12">
                            <v-icon class="mr-8 mr-md-12">mdi-map-marker-outline</v-icon>
                            <span class="font-weight-medium">주소</span>
                        </v-sheet>
                    </v-col>
                    <v-col cols="12" md="">
                        <p class="grey--text line-height-15">충남 금산군 금산읍 비단로 382 2층 구, 아인리 646-9 2층, ‘빙고(삼돌이) 아이스크림’ 2층</p>
                        <v-sheet outlined rounded="pill" class="mt-4 px-18 px-md-24 py-6 py-md-8 d-inline-flex">
                            <span class="font-weight-medium page-text grey--text line-height-1">사업추진단 사무실</span>
                        </v-sheet>
                    </v-col>
                </v-row>
            </div>
            <v-divider />
        </page-section>
    </client-page>
</template>

<script>
import SubVisual from "@/components/client/sub/sub-visual.vue";
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import PageSection from "@/components/client/sub/page-section.vue";

export default {
    components: {
        SubVisual,
        ClientPage,
        PageSection,
    },
    data: () => ({}),
};
</script>

<style lang="scss" scoped>
.map {
    aspect-ratio: 3 / 1;
}
@media (min-width: 768px) {
}
</style>
