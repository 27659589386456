<template>
    <dialogue v-model="shows">
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>

        <v-card tile flat class="map-dialog-card">
            <v-card-title class="pa-0">
                <v-img src="/images/story/map-dialog-header.jpg">
                    <v-card tile flat color="transparent" class="map-dialog-card__header">
                        <v-row justify="space-between" class="row--xxs">
                            <v-col cols="12">
                                <div class="tit">{{ story?.title }}</div>
                            </v-col>
                            <v-col cols="12" lg="6">
                                <div class="tit tit--xs font-weight-medium">{{ location }}</div>
                            </v-col>
                            <v-col cols="12" lg="6">
                                <div class="tit tit--xs font-weight-medium text-end">조사일자 : {{ $dayjs(story?.researchedAt).format("YYYY년 MM월 DD일") }}</div>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-img>
            </v-card-title>
            <v-card-text class="tit tit--xxs">
                <ul class="map-dialog-card__content">
                    <li class="line-height-15">
                        <div class="font-weight-bold mb-12 mb-md-16">기본정보</div>
                        <v-divider class="v-divider--dark"></v-divider>
                        <v-row justify="space-between" align="center" class="my-0">
                            <v-col cols="12" lg="auto">
                                <v-row no-gutters class="row--sm">
                                    <v-col cols="auto">
                                        <v-sheet min-width="52" class="font-weight-medium pr-8">위치</v-sheet>
                                    </v-col>
                                    <v-col>
                                        <span class="grey--text">{{ location }}</span>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12" lg="auto">
                                <v-row no-gutters class="row--sm">
                                    <v-col cols="auto">
                                        <v-sheet min-width="52" class="font-weight-medium pr-8">연락처</v-sheet>
                                    </v-col>
                                    <v-col>
                                        <v-sheet min-width="324" class="grey--text">{{ story?.phone }}</v-sheet>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-divider />
                        <v-row class="my-0">
                            <v-col cols="12">
                                <v-row class="row--sm">
                                    <v-col cols="auto">
                                        <span class="font-weight-medium">마을유래</span>
                                    </v-col>
                                    <v-col>
                                        <template v-if="story?.history">
                                            <div class="text-pre-wrap grey--text">{{ story.history }}</div>
                                        </template>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-divider />
                    </li>

                    <li>
                        <div class="font-weight-bold mb-12 mb-md-16">공동체 특성</div>
                        <map-dialog-population v-bind="{ population: story?.population }" />
                    </li>

                    <li>
                        <div class="font-weight-bold mb-12 mb-md-16">대표 모임 및 조직</div>
                        <v-simple-table class="group-table v-data-table--no-hover">
                            <template #default>
                                <thead>
                                    <tr>
                                        <th>구분</th>
                                        <th>규모</th>
                                        <th>주요활동</th>
                                        <th>비고</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <template v-for="(group, index) in story.groups">
                                        <tr :key="index">
                                            <td>
                                                {{ group?.name }}
                                            </td>
                                            <td>
                                                {{ group?.count }}명
                                            </td>
                                            <td class="text-start">
                                                {{ group?.desc }}
                                            </td>
                                            <td>
                                                <template v-if="group.etc">
                                                    {{ group?.etc }}
                                                </template>
                                                <template v-else>-</template>
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </li>

                    <li>
                        <div class="font-weight-bold mb-12 mb-md-16">대표자원</div>
                        <v-divider class="v-divider--dark mb-10 mb-md-14" />
                        <map-dialog-resources v-bind="{ resources: story?.resources }" />
                        <v-divider class="mt-10 mt-md-14" />
                    </li>

                    <li>
                        <v-row class="row--xs">
                            <template v-for="(item, index) in story?.images">
                                <v-col v-if="item._image" :key="index" cols="4">
                                    <v-card tile flat :img="item?.image?.src">
                                        <v-responsive :aspect-ratio="175 / 128" />
                                    </v-card>
                                    <div class="grey pa-8 text-center white--text">
                                        {{ item?.desc }}
                                    </div>
                                </v-col>
                            </template>
                        </v-row>
                    </li>

                    <li>
                        <v-row no-gutters>
                            <v-col cols="3">
                                <v-img src="/images/story/catchphrase-people.svg"></v-img>
                            </v-col>
                            <v-col cols="9">
                                <v-card tile flat img="/images/story/catchphrase-background.svg" class="catchphrase-box">
                                    <div class="font-weight-bold mb-10">캐치프라이즈 한줄</div>
                                    <p class="grey--text mb-10 text-pre-wrap">{{ story?.catchphrase?.content }}</p>
                                    <v-row justify="end" class="row--xs tit tit--xxs">
                                        <v-col cols="auto">
                                            <span class="font-weight-medium">조사원</span>
                                        </v-col>
                                        <v-col cols="auto">
                                            <span class="grey--text">{{ story?.catchphrase?.researcher }}</span>
                                        </v-col>
                                    </v-row>
                                </v-card>
                            </v-col>
                        </v-row>
                    </li>
                </ul>
            </v-card-text>
        </v-card>
    </dialogue>
</template>

<script>
import api from "@/api";
import { initRegion, initStory, STORY_REGION_TYPES } from "@/assets/variables";
import dialogue from "../dumb/dialogue.vue";
import MapDialogPopulation from "./map-dialog-population.vue";
import MapDialogResources from "./map-dialog-resources.vue";

export default {
    components: { dialogue, MapDialogPopulation, MapDialogResources },
    props: {
        region: { type: Object, default: initRegion },
    },
    data: () => ({
        shows: false,

        story: initStory(),
    }),
    computed: {
        location() {
            let region = this.story?.region || [];
            return `금산군 ${STORY_REGION_TYPES[region?.parent]?.text || ""} ${region?.name || ""}`;
        },
    },
    methods: {
        init() {
            if (this.region?._id) this.search();
        },
        async search() {
            try {
                let { stories } = await api.v1.story.gets({ params: { _region: this.region._id } });

                this.story = initStory(stories[0]);
            } catch (error) {
                this.$handleError(error);
            }
        },
    },
    watch: {
        shows() {
            if(this.shows) this.init();
        }
    }
};
</script>

<style lang="scss" scoped>
.v-divider {
    &--dark {
        border-width: 1px;
        border-color: var(--v-grey-darken4);
    }
}
.map-dialog-card {
    &__header {
        height: 100%;
        display: flex;
        align-items: flex-end;
        color: #fff;
        padding: 12px 12px;
    }
    > .v-card__text {
        color: var(--v-grey-darken4);
        padding: 12px 12px 12px !important;
    }

    &__content {
        margin: -16px;
        > li {
            padding: 16px;
        }
    }
}
.group-table {
    > .v-data-table__wrapper {
        > table {
            > thead > tr > th,
            > tbody > tr > td {
                font-size: 16px;
            }
            > thead {
                > tr {
                    > th {
                        font-size: 16px;
                        border-bottom: thin solid var(--v-grey-darken4) !important;
                    }
                }
            }
        }
    }
}
.catchphrase-box {
    min-height: 100%;
    background-position-x: left !important;
    background-size: cover;
    line-height: 1.5;
    padding: 18px;
    padding-left: 36px;
}
@media (min-width: 1024px) {
    .map-dialog-card {
        &__header {
            padding: 24px 48px;
        }
        > .v-card__text {
            padding: 40px 60px 60px !important;
        }
        &__content {
            margin: -20px;
            > li {
                padding: 20px;
            }
        }
    }
    .group-table {
        > .v-data-table__wrapper {
            > table {
                > thead > tr > th,
                > tbody > tr > td {
                    font-size: 18px;
                }
            }
        }
    }
    .catchphrase-box {
        padding: 24px;
        padding-left: 48px;
    }
}
::v-deep(.v-textarea) {
    font-size: 20px;
}
</style>