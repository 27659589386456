var render = function render(){
  var _vm$person, _vm$person$thumb, _vm$person2, _vm$person3, _vm$person4;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('sub-visual', {
    attrs: {
      "sh": "금산에 활력을 불어넣는 사람들",
      "text": "금산의 긍정적 변화와 새로운 활력을 이끄는 주민조직 및 활동가를 소개합니다.",
      "image": "/images/people/visual.jpg"
    }
  }), _c('page-section', {
    staticClass: "page-section--first"
  }, [_c('v-row', {
    staticClass: "ma-n20"
  }, [_c('v-col', {
    staticClass: "pa-20",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-card', {
    attrs: {
      "img": (_vm$person = _vm.person) === null || _vm$person === void 0 ? void 0 : (_vm$person$thumb = _vm$person.thumb) === null || _vm$person$thumb === void 0 ? void 0 : _vm$person$thumb.src,
      "tile": "",
      "flat": ""
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 572 / 382
    }
  })], 1)], 1), _c('v-col', {
    staticClass: "pa-20",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('div', {
    staticClass: "tit font-weight-bold line-height-1 mb-18 mb-md-24"
  }, [_vm._v(_vm._s((_vm$person2 = _vm.person) === null || _vm$person2 === void 0 ? void 0 : _vm$person2.name))]), _c('ul', {
    staticClass: "information-list page-text page-text--lg line-height-15"
  }, [_vm._l((_vm$person3 = _vm.person) === null || _vm$person3 === void 0 ? void 0 : _vm$person3.details, function (detail, index) {
    return [_c('li', {
      key: index
    }, [_c('v-row', {
      attrs: {
        "no-gutters": ""
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('v-sheet', {
      staticClass: "font-weight-medium",
      attrs: {
        "width": "110"
      }
    }, [_vm._v(" " + _vm._s(detail === null || detail === void 0 ? void 0 : detail.name) + " ")])], 1), _c('v-col', {
      staticClass: "grey--text"
    }, [_vm._v(" " + _vm._s(detail === null || detail === void 0 ? void 0 : detail.desc) + " ")])], 1)], 1)];
  })], 2)])], 1)], 1), _c('page-section', {
    staticClass: "pt-0 page-section--last"
  }, [_c('div', {
    staticClass: "tit font-weight-bold line-height-1"
  }, [_vm._v(_vm._s(_vm.title))]), _c('v-divider', {
    staticClass: "v-divider--dark my-18 my-md-24"
  }), !_vm.isGroup ? _c('people-view-slide', _vm._b({}, 'people-view-slide', {
    images: (_vm$person4 = _vm.person) === null || _vm$person4 === void 0 ? void 0 : _vm$person4.images
  }, false)) : _c('people-view-list')], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }