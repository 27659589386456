<template>
    <div class="line-height-15">
        <map-dialog-resource-item v-if="resources?.economy?.shows" v-bind="{ title: '경제자원', desc: resources?.economy?.desc }" />
        <map-dialog-resource-item v-if="resources?.facility?.shows" v-bind="{ title: '시설자원', desc: resources?.facility?.desc }" />
        <map-dialog-resource-item v-if="resources?.history?.shows" v-bind="{ title: '역사문화자원', desc: resources?.history?.desc }" />
        <map-dialog-resource-item v-if="resources?.landscape?.shows" v-bind="{ title: '경관자원', desc: resources?.landscape?.desc }" />
        <map-dialog-resource-item v-if="resources?.village?.shows" v-bind="{ title: '마을소유자원', desc: resources?.village?.desc }" />
        <map-dialog-resource-item v-if="resources?.around?.shows" v-bind="{ title: '주변연계자원', desc: resources?.around?.desc }" />
    </div>
</template>

<script>
import { initStoryResources } from "@/assets/variables";
import MapDialogResourceItem from "./map-dialog-resource-item.vue";
export default {
    components: { MapDialogResourceItem },
    props: {
        resources: { type: Object, default: initStoryResources },
    },
};
</script>

<style>
</style>