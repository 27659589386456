<template>
    <div class="sub-head">
        <div class="sub-visual">
            <v-container style="overflow: hidden">
                <div class="sub-visual__title" data-aos="fade-right">
                    <div :class="image ? '' : 'mx-auto'">
                        <h2 class="tit tit--xl line-height-12 pointed-title">
                            {{ sh }}
                        </h2>
                        <template v-if="text">
                            <p class="mt-12 mt-md-16 page-text page-text--lg">
                                {{ text }}
                            </p>
                        </template>
                    </div>
                </div>
            </v-container>
            <template v-if="image">
                <v-img :src="`${image}`" class="d-md-none" />
                <div :style="`background-image: url(${image});`" class="sub-visual__image d-none d-md-block" />
            </template>
        </div>

        <template v-if="tabActive">
            <client-gnb className="sub-tab" :tabActive="tabActive" :sh="sh" id="sub-tab" />
        </template>
    </div>
</template>

<script>
import ClientGnb from "@/components/client/inc/client-gnb.vue";

export default {
    props: {
        sh: { type: String, default: "" },
        text: { type: String, default: "" },
        tabActive: { type: String, default: null },
        image: { type: String, default: null },
    },
    components: {
        ClientGnb,
    },
    data: () => {
        return {};
    },
    created() {},
    mounted() {
        this.init();
    },
    methods: {
        init: function () {},
    },
};
</script>

<style lang="scss" scoped>
/* sub-visual */
.sub-visual {
    &__title {
        padding: 30px 0 30px;
        display: flex;
        align-items: center;
    }
    .pointed-title {
        &::after {
            right: -26px;
        }
    }
}
@media (min-width: 768px) {
    .sub-visual {
        &__image {
            height: 300px;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: bottom;
        }
    }
}
@media (min-width: 1464px) {
    .sub-visual {
        &__title {
            padding: 100px 0 60px;
            height: 270px;
        }
        &__image {
            background-size: 110%;
            background-attachment: fixed;
            background-position: center;
        }
        .pointed-title {
            &::after {
                right: -36px;
            }
        }
    }
}
@media (min-width: 1920px) {
    .sub-visual {
        &__title {
            padding: 100px 0 60px;
            height: 270px;
        }
        &__image {
            background-size: contain;
            background-attachment: fixed;
            background-position: center 18px;
        }
        .pointed-title {
            &::after {
                right: -36px;
            }
        }
    }
}
</style>