var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('sub-visual', {
    attrs: {
      "sh": "소개",
      "tabActive": "인사말",
      "text": "금산군에서 나의 자리를 찾다. 금산군 농촌신활력플러스사업을 소개합니다.",
      "image": "/images/about/visual.jpg"
    }
  }), _c('page-section', {
    staticClass: "page-section--first page-section--last"
  }, [_c('div', {
    staticClass: "tit-wrap"
  }, [_c('v-divider', {
    staticClass: "v-divider--dark"
  }), _c('div', {
    staticClass: "tit text-center py-24 py-md-36"
  }, [_vm._v(" 안녕하십니까. "), _c('br'), _c('strong', [_vm._v("금산군 농촌신활력플러스사업 추진단장 길성용")]), _vm._v("입니다. ")]), _c('v-divider', {
    staticClass: "v-divider--dark"
  })], 1), _c('div', {
    staticClass: "page-text page-text--lg line-height-16"
  }, [_c('p', [_vm._v(" 저희 금산군 농촌신활력플러스사업은 ‘사람과 사람으로 연결된 하나의 이음공동체 금산’을 모토로, 지역에 사람과 자원을 남겨 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 금산에 새로운 활력을 불어 넣는 사업으로써 지난 2019년 선정되어 2020년 4월부터 본격적인 사업을 수행해 왔습니다. ")]), _c('p', [_vm._v(" 고령화와 지역 소멸 위기에 처해있는 금산군의 문제를 해결하기 위해 기존 지역민의 역량을 강화시키고, 돌봄사각지대에 있는 주민에게는 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 문화와 돌봄 활동을 지원하며, 금산의 청년들과 금산에 이주하고자하는 청년들의 창업 및 정착을 지원하는 등 다양한 분야의 활동을 통해 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 금산군에 신활력을 불어넣고자 합니다. ")]), _c('p', [_vm._v(" 이와 더불어 마을소액사업지원으로 마을들이 타 사업에 참여할 역량을 키우고 주민동아리를 조직화하여 자생력 있는 액션그룹으로 성장시켜 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 지역에 선한 영향력을 끼칠 수 있는 주민조직으로 교육시키고 사업을 지원합니다. ")]), _c('p', [_vm._v(" 앞으로도 여러분들의 많은 관심과 성원 부탁드리며, "), _c('br', {
    staticClass: "d-none d-sm-block"
  }), _vm._v(" 언제나 신활력이 넘치는 금산을 만들 수 있도록 최선을 다하겠습니다. ")])]), _c('div', {
    staticClass: "tit-wrap"
  }), _c('div', {
    staticClass: "text-end primary--text font-size-18"
  }, [_vm._v("금산군 농촌신활력 플러스사업 추진단장")]), _c('v-img', {
    staticClass: "ml-auto mt-12 mt-md-14",
    attrs: {
      "max-width": "175",
      "src": "/images/about/greetings/sign.svg"
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }