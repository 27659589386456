<template>
    <client-page>
        <sub-visual sh="소개" tabActive="사업소개" text="금산군에서 나의 자리를 찾다. 금산군 농촌신활력플러스사업을 소개합니다." image="/images/about/visual.jpg" />

        <page-section class="page-section--first" subtitle="Vison" title="비전">
            <v-card outlined rounded="pill" max-width="1020" class="mx-auto pa-16 pa-md-40">
                <v-row>
                    <template v-for="item in visions">
                        <v-col cols="12" md="4" :key="item.text">
                            <v-sheet rounded="circle" :color="item.color" class="pa-24 overflow-visible">
                                <v-responsive :aspect-ratio="1 / 1" class="overflow-visible">
                                    <v-sheet rounded="circle" class="font-size-20 text-pre-wrap text-center line-height-16 box-shadow">
                                        <v-responsive :aspect-ratio="1 / 1" class="d-flex justify-center align-center">
                                            <v-row class="row--lg ma-0">
                                                <v-col cols="12">
                                                    {{ item.text }}
                                                </v-col>
                                            </v-row>
                                        </v-responsive>
                                    </v-sheet>
                                </v-responsive>
                            </v-sheet>
                        </v-col>
                    </template>
                </v-row>
            </v-card>
        </page-section>

        <page-section class="grey lighten-5" title="핵심가치" subtitle="Core Value">
            <v-card rounded="pill" flat class="pa-24 pa-md-42 tit-wrap tit-wrap--lg">
                <v-row justify="center" align="center">
                    <v-col cols="12" md="auto">
                        <div class="tit tit--sm font-weight-bold text-center">슬로건</div>
                    </v-col>
                    <v-col cols="auto">
                        <v-img max-width="406" width="100%" src="/images/about/business/core-value.svg" alt="사람과 사람으로 연결된 하나의 이음공동체 금산" />
                    </v-col>
                </v-row>
            </v-card>
            <div class="core-value-image">
                <v-row justify="center">
                    <template v-for="index in 3">
                        <v-col cols="6" md="4" :key="index">
                            <v-img :src="`/images/about/business/value-${index}.jpg`" class="mx-auto" />
                        </v-col>
                    </template>
                </v-row>
            </div>
        </page-section>

        <page-section title="활성화체계" subtitle="Promotion System">
            <div class="system-title pt-28 pt-md-40 pb-24 pb-md-32">마을에서 <span class="primary--text">새로운</span> 나의 역할을 찾다. <span class="primary--text">新</span>활력 금산살이 3⁺</div>
            <v-row justify="center">
                <template v-for="(item, index) in systems">
                    <v-col :key="index" cols="12" md="6" lg="4">
                        <promotion-system :index="index" :item="item" />
                    </v-col>
                </template>
            </v-row>
        </page-section>

        <page-section>
            <div class="system-title">종합구상도</div>
            <v-img src="/images/about/business/plan.jpg" />
        </page-section>

        <page-section class="page-section--last">
            <div class="system-title mb-24 mb-md-32">단계별 주민 공동체 활동지원으로 지역 내 주민조직의 지속성장 기대</div>
            <v-img src="/images/about/business/step.jpg" />
        </page-section>
    </client-page>
</template>

<script>
import SubVisual from "@/components/client/sub/sub-visual.vue";
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import PageSection from "@/components/client/sub/page-section.vue";
import PromotionSystem from "@/components/client/about/business/promotion-system.vue";
export default {
    components: {
        SubVisual,
        ClientPage,
        PageSection,
        PromotionSystem,
    },
    data: () => ({
        visions: [
            {
                color: "primary lighten-4",
                text: "다양한 일상생활 분야의\r\n주민공동체 활동을 통한\r\n삶의 질 향상",
            },
            {
                color: "secondary lighten-4",
                text: "기존 주민조직 간\r\n연계강화 및 지역사회의\r\n연결망 구축",
            },
            {
                color: "tertiary lighten-4",
                text: "중간지원조직의 체계화와\r\n지원 시스템 확립",
            },
        ],
        systems: [
            {
                title: "함께해봅시다!",
                keyword: "마을가꿈",
                color: "primary",
                subtitle: "주민공동체 활성화를 통한 서비스 자생력 확보",
                content: [
                    "주민공모사업 운영으로 분야별 주민조직 발굴",
                    "수요맞춤형 서비스 제공으로 주민 거주만족도 향상",
                    "마을활동가 연계를 통해 지속적인 서비스 기반 마련",
                    //
                ],
                children: [
                    {
                        title: "희망미래 주민공모사업",
                        children: [
                            "(S/W) 주민공모사업 컨설팅 교육",
                            "(S/W) 읍면단위 소규모사업 지원",
                            //
                        ],
                    },
                    {
                        title: "공존공생학교 운영",
                        children: [
                            "(S/W) 수요 맞춤형 학교 운영",
                            "(Hn/W) 희망미래배달부 양성",
                            //
                        ],
                    },
                    {
                        title: "금산 희망미래보듬터 운영",
                        children: [
                            "(S/W) 마을활동가 네트워크 구축",
                            "(S/W) 희망미래보듬터 활동지원",
                            //
                        ],
                    },
                ],
            },
            {
                title: "잘 살아봅시다!",
                keyword: "살림키움",
                color: "secondary",
                subtitle: "주민 협동조직 육성 및 사회적경제조직 기반 마련",
                content: [
                    "주민공모사업 운영으로 분야별 주민조직 발굴",
                    "수요맞춤형 서비스 제공으로 주민 거주만족도 향상",
                    "마을활동가 연계를 통해 지속적인 서비스 기반 마련",
                    //
                ],
                children: [
                    {
                        title: "新활력 아카데미 운영",
                        children: [
                            "(S/W) 주민조직 교육 컨설팅",
                            "(S/W) 아카데미 기수제 운영",
                            //
                        ],
                    },
                    {
                        title: "신활력+ 액션그룹 지원",
                        children: [
                            "(Hn/W) 액션그룹 발굴 및 교육 지원",
                            "(S/W) 액션그룹 사업 지원",
                            //
                        ],
                    },
                    {
                        title: "청년 끌어드림(dream) 프로젝트",
                        children: [
                            "(S/W) 금산 지역활력단 운영",
                            "(S/W) 지역기반 창업 지원",
                            //
                        ],
                    },
                ],
            },
            {
                title: "만들어봅시다!",
                keyword: "기반다짐",
                color: "tertiary",
                subtitle: "통합지원플랫폼 구축으로 서비스 전달기능 강화",
                content: [
                    "주민공모사업 운영으로 분야별 주민조직 발굴",
                    "수요맞춤형 서비스 제공으로 주민 거주만족도 향상",
                    "마을활동가 연계를 통해 지속적인 서비스 기반 마련",
                    //
                ],
                children: [
                    {
                        title: "희망미래 주민공모사업",
                        children: [
                            "(H/W) 주민공모사업 컨설팅 교육",
                            "(S/W) 읍면단위 소규모사업 지원",
                            //
                        ],
                    },
                    {
                        title: "공존공생학교 운영",
                        children: [
                            "(S/W) 수요 맞춤형 학교 운영",
                            "(S/W) 희망미래배달부 양성",
                            //
                        ],
                    },
                    {
                        title: "금산 희망미래보듬터 운영",
                        children: [
                            "(S/W) 마을활동가 네트워크 구축",
                            "(S/W) 희망미래보듬터 활동지원",
                            // 
                        ],
                    },
                ],
            },
        ],
    }),
};
</script>

<style lang="scss" scoped>
.box-shadow {
    box-shadow: 0 0 14px 0 rgba(9, 20, 48, 0.1);
}
.system-title {
    font-family: "GmarketSans";
    font-weight: 500;
    font-size: 20px;
    line-height: 1.2;
    text-align: center;
}
@media (min-width: 768px) {
    .core-value-image {
        position: relative;
        &::before {
            content: "";
            position: absolute;
            top: 50%;
            left: -50vw;
            width: 150vw;
            height: 200px;
            background-color: #fff;
        }
    }
    .system-title {
        font-size: 26px;
        line-height: 1;
    }
}
</style>
