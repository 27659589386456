var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('sub-visual', {
    attrs: {
      "sh": "아카이빙",
      "tabActive": "연혁",
      "text": "금산군에 있는 260개 마을의 발전을 위한 기초자료를 구축하고자 마을자원과 현황을 조사하고, 주민의 사업수요를 들어보았습니다.",
      "image": "/images/archiving/visual.jpg"
    }
  }), _c('page-section', {
    staticClass: "page-section--first page-section--last",
    attrs: {
      "title": "연혁",
      "subtitle": "History"
    }
  }, [_c('ul', {
    staticClass: "history"
  }, [_vm._l(_vm.histories, function (item, index) {
    return [_c('li', {
      key: index
    }, [_c('v-row', {
      staticClass: "row--xl"
    }, [_c('v-col', {
      attrs: {
        "cols": "12",
        "md": "3"
      }
    }, [_c('div', {
      staticClass: "history__year tit tit--lg"
    }, [_vm._v(" " + _vm._s(item.year) + " ")])]), _c('v-col', {
      staticClass: "page-text",
      attrs: {
        "cols": "12",
        "md": "9"
      }
    }, [_c('ul', {
      staticClass: "history__child"
    }, [_vm._l(item.children, function (child, index) {
      return [_c('li', {
        key: index
      }, [_c('v-row', {
        staticClass: "row--xs"
      }, [_c('v-col', {
        attrs: {
          "cols": "auto"
        }
      }, [_c('span', {
        staticClass: "history__month"
      }, [_vm._v(" " + _vm._s(child.month) + " ")])]), _c('v-col', {
        staticClass: "grey--text line-height-15"
      }, [_c('ul', {
        staticClass: "history__content"
      }, [_vm._l(child.children, function (grandChild, index) {
        return [_c('li', {
          key: index
        }, [_c('v-row', {
          staticClass: "ma-n2"
        }, [_c('v-col', {
          staticClass: "pa-2",
          attrs: {
            "cols": "auto"
          }
        }, [_vm._v("·")]), _c('v-col', {
          staticClass: "pa-2"
        }, [_vm._v(" " + _vm._s(grandChild) + " ")])], 1)], 1)];
      })], 2)])], 1)], 1)];
    })], 2)])], 1)], 1)];
  })], 2)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }