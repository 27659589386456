<template>
    <v-row justify="end" align="center">
        <v-col cols="12" md="10" order-md="2">
            <div class="swiper-container" ref="swiperRef">
                <div class="swiper-wrapper">
                    <template v-for="(image, index) in images">
                        <div :key="index" class="swiper-slide">
                            <img :src="image?.src" alt="" :key="index" class="ma-auto d-block" />
                        </div>
                    </template>
                </div>
            </div>
        </v-col>
        <v-col cols="auto" md="1" order-md="1">
            <v-btn class="swiper-button--prev" icon outlined width="56" height="56" color="primary" @click="slidePrev">
                <v-img src="/images/icon/icon-button-left.svg" max-width="10" />
            </v-btn>
        </v-col>
        <v-col cols="auto" md="1" order-md="3" class="d-flex justify-end">
            <v-btn class="swiper-button--next" icon outlined width="56" height="56" color="primary">
                <v-img src="/images/icon/icon-button-right.svg" max-width="10" />
            </v-btn>
        </v-col>
    </v-row>
</template>

<script>
export default {
    props: {
        images: { type: Array, default: () => [] },
    },
    methods: {
        async init() {
            this.$nextTick(() => {
                setTimeout(() => {
                    this.peopleSlide = new Swiper(".swiper-container", {
                        autoplay: {
                            delay: 3000,
                            disableOnInteraction: false,
                        },
                        speed: 500,
                        loop: true,
                        navigation: {
                            nextEl: ".swiper-button--next",
                            prevEl: ".swiper-button--prev",
                        },
                    });
                }, 1000);
            });
        },
    },
    mounted() {
        this.init();
    },
};
</script>

<style lang="scss" scoped>
.swiper-wrapper {
    align-items: center;
}
</style>