var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "mx-n10 my-0"
  }, [_c('v-col', {
    staticClass: "pa-10",
    attrs: {
      "cols": "3",
      "md": "2"
    }
  }, [_c('span', {
    staticClass: "font-weight-medium"
  }, [_vm._v(_vm._s(_vm.title))])]), _c('v-col', {
    staticClass: "pa-10",
    attrs: {
      "cols": "9",
      "md": "10"
    }
  }, [_c('span', {
    staticClass: "text-pre-wrap grey--text"
  }, [_vm._v(_vm._s(_vm.desc))])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }