<template>
    <client-page>
        <sub-visual sh="금산에 활력을 불어넣는 사람들" text="금산의 긍정적 변화와 새로운 활력을 이끄는 주민조직 및 활동가를 소개합니다." image="/images/people/visual.jpg" />

        <page-section class="page-section--first page-section--last">
            <v-row>
                <template v-for="(item, index) in people">
                    <v-col :key="index" cols="6" md="4">
                        <v-card :to="`./people/${item?._id}`" tile flat class="pb-14 pb-md-16">
                            <v-card :img="item?.thumb?.src" flat outlined tile>
                                <v-responsive :aspect-ratio="11 / 8" />
                            </v-card>
                            <v-card-title class="tit tit--xs justify-center pa-0 pt-16 pt-md-20">
                                {{ item?.name }}
                            </v-card-title>
                            <div class="d-flex justify-center mx-n4 py-12 py-md-16">
                                <template v-for="n in 3">
                                    <div :key="n" class="px-4">
                                        <v-sheet width="4" height="4" rounded="circle" color="primary" />
                                    </div>
                                </template>
                            </div>
                            <v-card-text class="page-text text-center pa-0">
                                {{ item?.summary }}
                            </v-card-text>
                        </v-card>
                    </v-col>
                </template>
            </v-row>
        </page-section>
    </client-page>
</template>

<script>
import api from "@/api";

import SubVisual from "@/components/client/sub/sub-visual.vue";
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import PageSection from "@/components/client/sub/page-section.vue";

export default {
    components: {
        SubVisual,
        ClientPage,
        PageSection,
    },
    data: () => ({
        people: [],
    }),
    mounted() {
        this.search();
    },
    methods: {
        async search() {
            try {
                let { people } = await api.v1.people.gets({ params: { isMain: true } });
                this.people = people;
            } catch (error) {
                this.$handleError(error);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@media (min-width: 768px) {
}
</style>
