<template>
    <v-row>
        <template v-for="(person, index) in people">
            <v-col :key="index" cols="6" md="4">
                <v-card tile flat class="pb-14 pb-md-16">
                    <v-card :img="person?.thumb?.src" :to="`./${person._id}`" tile flat outlined>
                        <v-responsive :aspect-ratio="384 / 256" />
                    </v-card>
                    <v-card-title class="tit tit--xs justify-center pa-0 pt-16 pt-md-20">
                        {{ person?.name }}
                    </v-card-title>
                    <div class="d-flex justify-center mx-n4 py-12 py-md-16">
                        <template v-for="n in 3">
                            <div :key="n" class="px-4">
                                <v-sheet width="4" height="4" rounded="circle" color="primary" />
                            </div>
                        </template>
                    </div>
                    <v-card-text class="page-text text-center pa-0">
                        {{ person?.summary }}
                    </v-card-text>
                </v-card>
            </v-col>
        </template>
    </v-row>
</template>

<script>
import api from "@/api";
export default {
    data: () => ({
        people: [],
    }),
    computed: {
        _people() {
            return this.$route.params?._people;
        },
    },
    mounted() {
        this.search();
    },
    methods: {
        async search() {
            try {
                let { people } = await api.v1.people.gets({ params: { _group: this._people } });
                this.people = people;
            } catch (error) {
                this.$handleError(error);
            }
        },
    },
    watch: {
        _people() {
            this.search();
        },
    },
};
</script>