var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('sub-visual', {
    attrs: {
      "sh": "금산에 활력을 불어넣는 사람들",
      "text": "금산의 긍정적 변화와 새로운 활력을 이끄는 주민조직 및 활동가를 소개합니다.",
      "image": "/images/people/visual.jpg"
    }
  }), _c('page-section', {
    staticClass: "page-section--first page-section--last"
  }, [_c('v-row', [_vm._l(_vm.people, function (item, index) {
    var _item$thumb;
    return [_c('v-col', {
      key: index,
      attrs: {
        "cols": "6",
        "md": "4"
      }
    }, [_c('v-card', {
      staticClass: "pb-14 pb-md-16",
      attrs: {
        "to": `./people/${item === null || item === void 0 ? void 0 : item._id}`,
        "tile": "",
        "flat": ""
      }
    }, [_c('v-card', {
      attrs: {
        "img": item === null || item === void 0 ? void 0 : (_item$thumb = item.thumb) === null || _item$thumb === void 0 ? void 0 : _item$thumb.src,
        "flat": "",
        "outlined": "",
        "tile": ""
      }
    }, [_c('v-responsive', {
      attrs: {
        "aspect-ratio": 11 / 8
      }
    })], 1), _c('v-card-title', {
      staticClass: "tit tit--xs justify-center pa-0 pt-16 pt-md-20"
    }, [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : item.name) + " ")]), _c('div', {
      staticClass: "d-flex justify-center mx-n4 py-12 py-md-16"
    }, [_vm._l(3, function (n) {
      return [_c('div', {
        key: n,
        staticClass: "px-4"
      }, [_c('v-sheet', {
        attrs: {
          "width": "4",
          "height": "4",
          "rounded": "circle",
          "color": "primary"
        }
      })], 1)];
    })], 2), _c('v-card-text', {
      staticClass: "page-text text-center pa-0"
    }, [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : item.summary) + " ")])], 1)], 1)];
  })], 2)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }