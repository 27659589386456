<template>
    <div>
        <v-card flat :color="item.color" rounded class="pa-16 pa-md-20 text-center">
            <div class="font-size-20 font-weight-bold white--text mb-8">목표 {{ index + 1 }}</div>
            <v-sheet rounded="pill" class="px-14 py-10 font-size-20 font-gmarket line-height-1" :class="`${item.color}--text`">
                {{ item.title }}
                <span class="font-weight-bold"> {{ item.keyword }}⁺ </span>
            </v-sheet>

            <div class="font-size-18 font-weight-medium mt-20 mb-8">
                {{ item.subtitle }}
            </div>
            <v-sheet :class="item.color" class="rounded-lg lighten-5 pa-16 pa-md-20 text-start">
                <ul class="content-list">
                    <template v-for="(content, index) in item.content">
                        <li :key="index">
                            <v-row no-gutters>
                                <v-col cols="auto">
                                    <v-sheet :color="item.color" width="4" height="4" rounded="circle" class="mr-6 mt-6" />
                                </v-col>
                                <v-col>
                                    {{ content }}
                                </v-col>
                            </v-row>
                        </li>
                    </template>
                </ul>
            </v-sheet>
        </v-card>

        <ul class="children-list">
            <template v-for="(child, i) in item.children">
                <li :key="i">
                    <v-sheet :style="`border-color: var(--v-${item.color}-base)`" outlined rounded="pill" class="children-title font-gmarket" :class="`${item.color}--text`">
                        {{ index + 1 }}-{{ i + 1 }}
                        {{ child.title }}
                    </v-sheet>
                    <ul class="grandchild-list px-16 px-md-20">
                        <template v-for="(grandChild, i) in child.children">
                            <li :key="i">
                                {{ grandChild }}
                            </li>
                        </template>
                    </ul>
                </li>
            </template>
        </ul>
    </div>
</template>

<script>
export default {
    props: {
        index: { type: Number, default: null },
        item: { type: String, default: null },
    },
};
</script>

<style lang="scss" scoped>
.content-list {
    > li {
        font-size: 14px;
        color: var(--v-grey-darken2);
        margin-bottom: 8px;
        &:last-child {
            margin-bottom: 0;
        }
    }
}
.children-list {
    margin-top: 32px;
    > li {
        margin-bottom: 20px;
        &:last-child {
            margin-bottom: 0;
        }
    }
}
.children-title {
    font-size: 20px;
    border-width: 2px;
    padding: 6px 16px;
    margin-bottom: 18px;
    font-weight: 500;
}
.grandchild-list {
    > li {
        margin-bottom: 6px;
        font-weight: 500;
        &:last-child {
            margin-bottom: 0;
        }
    }
}
@media (min-width: 768px) {
    .children-title {
        padding: 8px 18px;
    }
}
</style>