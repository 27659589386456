<template>
    <v-row class="row--xxs">
        <v-col cols="6" sm="4" lg="2">
            <map-dialog-population-item v-bind="{ title: '주민수', image: '/images/story/community-1.svg', number: population?.citizen, unit: '명' }" />
        </v-col>
        <v-col cols="6" sm="4" lg="2">
            <map-dialog-population-item v-bind="{ title: '가구수', image: '/images/story/community-2.svg', number: population?.household, unit: '세대' }" />
        </v-col>
        <v-col cols="6" sm="4" lg="2">
            <map-dialog-population-item v-bind="{ title: '아동 · 청소년 수', image: '/images/story/community-3.svg', number: population?.teenager, unit: '명' }" />
        </v-col>
        <v-col cols="6" sm="4" lg="2">
            <map-dialog-population-item v-bind="{ title: '다문화자녀 수', image: '/images/story/community-4.svg', number: population?.multiCulture, unit: '명' }" />
        </v-col>
        <v-col cols="6" sm="4" lg="2">
            <map-dialog-population-item v-bind="{ title: '외국인(성인) 수', image: '/images/story/community-5.svg', number: population?.foreigner, unit: '명' }" />
        </v-col>
        <v-col cols="6" sm="4" lg="2">
            <map-dialog-population-item v-bind="{ title: '최근 5년간\r\n귀농귀촌인 수', image: '/images/story/community-6.svg', number: population?.farmer, unit: '명' }" />
        </v-col>
    </v-row>
</template>

<script>
import { initStoryPopulation } from '@/assets/variables';
import MapDialogPopulationItem from "./map-dialog-population-item.vue";
export default {
    components: {
        MapDialogPopulationItem,
    },
    props: {
        population: { type: Object, default: initStoryPopulation}
    }
};
</script>

<style>
</style>