<template>
    <client-page class="wrap--sub">
        <!-- 서브비주얼 -->
        <sub-visual :visual="'개인정보처리방침'"></sub-visual>

        <section class="section">
            <v-container>
                <terms-component code="privacy" />
            </v-container>
        </section>
    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";

import TermsComponent from "@/components/client/terms/terms-component.vue";

export default {
    components: {
        ClientPage,
        SubVisual,
        TermsComponent,
    },
};
</script>
