var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    attrs: {
      "justify": "end",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "10",
      "order-md": "2"
    }
  }, [_c('div', {
    ref: "swiperRef",
    staticClass: "swiper-container"
  }, [_c('div', {
    staticClass: "swiper-wrapper"
  }, [_vm._l(_vm.images, function (image, index) {
    return [_c('div', {
      key: index,
      staticClass: "swiper-slide"
    }, [_c('img', {
      key: index,
      staticClass: "ma-auto d-block",
      attrs: {
        "src": image === null || image === void 0 ? void 0 : image.src,
        "alt": ""
      }
    })])];
  })], 2)])]), _c('v-col', {
    attrs: {
      "cols": "auto",
      "md": "1",
      "order-md": "1"
    }
  }, [_c('v-btn', {
    staticClass: "swiper-button--prev",
    attrs: {
      "icon": "",
      "outlined": "",
      "width": "56",
      "height": "56",
      "color": "primary"
    },
    on: {
      "click": _vm.slidePrev
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/images/icon/icon-button-left.svg",
      "max-width": "10"
    }
  })], 1)], 1), _c('v-col', {
    staticClass: "d-flex justify-end",
    attrs: {
      "cols": "auto",
      "md": "1",
      "order-md": "3"
    }
  }, [_c('v-btn', {
    staticClass: "swiper-button--next",
    attrs: {
      "icon": "",
      "outlined": "",
      "width": "56",
      "height": "56",
      "color": "primary"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/images/icon/icon-button-right.svg",
      "max-width": "10"
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }