var render = function render(){
  var _vm$story, _vm$story2, _vm$story3, _vm$story4, _vm$story5, _vm$story6, _vm$story7, _vm$story8, _vm$story8$catchphras, _vm$story9, _vm$story9$catchphras;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('dialogue', {
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on
        })];
      }
    }], null, true),
    model: {
      value: _vm.shows,
      callback: function ($$v) {
        _vm.shows = $$v;
      },
      expression: "shows"
    }
  }, [_c('v-card', {
    staticClass: "map-dialog-card",
    attrs: {
      "tile": "",
      "flat": ""
    }
  }, [_c('v-card-title', {
    staticClass: "pa-0"
  }, [_c('v-img', {
    attrs: {
      "src": "/images/story/map-dialog-header.jpg"
    }
  }, [_c('v-card', {
    staticClass: "map-dialog-card__header",
    attrs: {
      "tile": "",
      "flat": "",
      "color": "transparent"
    }
  }, [_c('v-row', {
    staticClass: "row--xxs",
    attrs: {
      "justify": "space-between"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "tit"
  }, [_vm._v(_vm._s((_vm$story = _vm.story) === null || _vm$story === void 0 ? void 0 : _vm$story.title))])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('div', {
    staticClass: "tit tit--xs font-weight-medium"
  }, [_vm._v(_vm._s(_vm.location))])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('div', {
    staticClass: "tit tit--xs font-weight-medium text-end"
  }, [_vm._v("조사일자 : " + _vm._s(_vm.$dayjs((_vm$story2 = _vm.story) === null || _vm$story2 === void 0 ? void 0 : _vm$story2.researchedAt).format("YYYY년 MM월 DD일")))])])], 1)], 1)], 1)], 1), _c('v-card-text', {
    staticClass: "tit tit--xxs"
  }, [_c('ul', {
    staticClass: "map-dialog-card__content"
  }, [_c('li', {
    staticClass: "line-height-15"
  }, [_c('div', {
    staticClass: "font-weight-bold mb-12 mb-md-16"
  }, [_vm._v("기본정보")]), _c('v-divider', {
    staticClass: "v-divider--dark"
  }), _c('v-row', {
    staticClass: "my-0",
    attrs: {
      "justify": "space-between",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "auto"
    }
  }, [_c('v-row', {
    staticClass: "row--sm",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-sheet', {
    staticClass: "font-weight-medium pr-8",
    attrs: {
      "min-width": "52"
    }
  }, [_vm._v("위치")])], 1), _c('v-col', [_c('span', {
    staticClass: "grey--text"
  }, [_vm._v(_vm._s(_vm.location))])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "auto"
    }
  }, [_c('v-row', {
    staticClass: "row--sm",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-sheet', {
    staticClass: "font-weight-medium pr-8",
    attrs: {
      "min-width": "52"
    }
  }, [_vm._v("연락처")])], 1), _c('v-col', [_c('v-sheet', {
    staticClass: "grey--text",
    attrs: {
      "min-width": "324"
    }
  }, [_vm._v(_vm._s((_vm$story3 = _vm.story) === null || _vm$story3 === void 0 ? void 0 : _vm$story3.phone))])], 1)], 1)], 1)], 1), _c('v-divider'), _c('v-row', {
    staticClass: "my-0"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "row--sm"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "font-weight-medium"
  }, [_vm._v("마을유래")])]), _c('v-col', [(_vm$story4 = _vm.story) !== null && _vm$story4 !== void 0 && _vm$story4.history ? [_c('div', {
    staticClass: "text-pre-wrap grey--text"
  }, [_vm._v(_vm._s(_vm.story.history))])] : _vm._e()], 2)], 1)], 1)], 1), _c('v-divider')], 1), _c('li', [_c('div', {
    staticClass: "font-weight-bold mb-12 mb-md-16"
  }, [_vm._v("공동체 특성")]), _c('map-dialog-population', _vm._b({}, 'map-dialog-population', {
    population: (_vm$story5 = _vm.story) === null || _vm$story5 === void 0 ? void 0 : _vm$story5.population
  }, false))], 1), _c('li', [_c('div', {
    staticClass: "font-weight-bold mb-12 mb-md-16"
  }, [_vm._v("대표 모임 및 조직")]), _c('v-simple-table', {
    staticClass: "group-table v-data-table--no-hover",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function () {
        return [_c('thead', [_c('tr', [_c('th', [_vm._v("구분")]), _c('th', [_vm._v("규모")]), _c('th', [_vm._v("주요활동")]), _c('th', [_vm._v("비고")])])]), _c('tbody', [_vm._l(_vm.story.groups, function (group, index) {
          return [_c('tr', {
            key: index
          }, [_c('td', [_vm._v(" " + _vm._s(group === null || group === void 0 ? void 0 : group.name) + " ")]), _c('td', [_vm._v(" " + _vm._s(group === null || group === void 0 ? void 0 : group.count) + "명 ")]), _c('td', {
            staticClass: "text-start"
          }, [_vm._v(" " + _vm._s(group === null || group === void 0 ? void 0 : group.desc) + " ")]), _c('td', [group.etc ? [_vm._v(" " + _vm._s(group === null || group === void 0 ? void 0 : group.etc) + " ")] : [_vm._v("-")]], 2)])];
        })], 2)];
      },
      proxy: true
    }])
  })], 1), _c('li', [_c('div', {
    staticClass: "font-weight-bold mb-12 mb-md-16"
  }, [_vm._v("대표자원")]), _c('v-divider', {
    staticClass: "v-divider--dark mb-10 mb-md-14"
  }), _c('map-dialog-resources', _vm._b({}, 'map-dialog-resources', {
    resources: (_vm$story6 = _vm.story) === null || _vm$story6 === void 0 ? void 0 : _vm$story6.resources
  }, false)), _c('v-divider', {
    staticClass: "mt-10 mt-md-14"
  })], 1), _c('li', [_c('v-row', {
    staticClass: "row--xs"
  }, [_vm._l((_vm$story7 = _vm.story) === null || _vm$story7 === void 0 ? void 0 : _vm$story7.images, function (item, index) {
    var _item$image;
    return [item._image ? _c('v-col', {
      key: index,
      attrs: {
        "cols": "4"
      }
    }, [_c('v-card', {
      attrs: {
        "tile": "",
        "flat": "",
        "img": item === null || item === void 0 ? void 0 : (_item$image = item.image) === null || _item$image === void 0 ? void 0 : _item$image.src
      }
    }, [_c('v-responsive', {
      attrs: {
        "aspect-ratio": 175 / 128
      }
    })], 1), _c('div', {
      staticClass: "grey pa-8 text-center white--text"
    }, [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : item.desc) + " ")])], 1) : _vm._e()];
  })], 2)], 1), _c('li', [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/images/story/catchphrase-people.svg"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "9"
    }
  }, [_c('v-card', {
    staticClass: "catchphrase-box",
    attrs: {
      "tile": "",
      "flat": "",
      "img": "/images/story/catchphrase-background.svg"
    }
  }, [_c('div', {
    staticClass: "font-weight-bold mb-10"
  }, [_vm._v("캐치프라이즈 한줄")]), _c('p', {
    staticClass: "grey--text mb-10 text-pre-wrap"
  }, [_vm._v(_vm._s((_vm$story8 = _vm.story) === null || _vm$story8 === void 0 ? void 0 : (_vm$story8$catchphras = _vm$story8.catchphrase) === null || _vm$story8$catchphras === void 0 ? void 0 : _vm$story8$catchphras.content))]), _c('v-row', {
    staticClass: "row--xs tit tit--xxs",
    attrs: {
      "justify": "end"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "font-weight-medium"
  }, [_vm._v("조사원")])]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "grey--text"
  }, [_vm._v(_vm._s((_vm$story9 = _vm.story) === null || _vm$story9 === void 0 ? void 0 : (_vm$story9$catchphras = _vm$story9.catchphrase) === null || _vm$story9$catchphras === void 0 ? void 0 : _vm$story9$catchphras.researcher))])])], 1)], 1)], 1)], 1)], 1)])])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }