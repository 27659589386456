<template>
    <v-card tile flat color="grey lighten-5">
        <v-responsive :aspect-ratio="1 / 1" class="d-flex justify-center align-center">
            <v-img max-width="92" :src="image" class="mx-auto" />
            <div class="text-center">
                <div class="tit tit--xxs text-pre-wrap line-height-1 my-8">
                    {{ title }}
                </div>
                <div class="tit tit--sm font-weight-bold line-height-1">{{ number }}{{ unit }}</div>
            </div>
        </v-responsive>
    </v-card>
</template>

<script>
export default {
    props: {
        title: { type: String, default: "" },
        image: { type: String, default: "" },
        number: { type: Number, default: 0 },
        unit: { type: String, default: "명" },
    },
};
</script>

<style>
</style>