var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-card', {
    staticClass: "pa-16 pa-md-20 text-center",
    attrs: {
      "flat": "",
      "color": _vm.item.color,
      "rounded": ""
    }
  }, [_c('div', {
    staticClass: "font-size-20 font-weight-bold white--text mb-8"
  }, [_vm._v("목표 " + _vm._s(_vm.index + 1))]), _c('v-sheet', {
    staticClass: "px-14 py-10 font-size-20 font-gmarket line-height-1",
    class: `${_vm.item.color}--text`,
    attrs: {
      "rounded": "pill"
    }
  }, [_vm._v(" " + _vm._s(_vm.item.title) + " "), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.item.keyword) + "⁺ ")])]), _c('div', {
    staticClass: "font-size-18 font-weight-medium mt-20 mb-8"
  }, [_vm._v(" " + _vm._s(_vm.item.subtitle) + " ")]), _c('v-sheet', {
    staticClass: "rounded-lg lighten-5 pa-16 pa-md-20 text-start",
    class: _vm.item.color
  }, [_c('ul', {
    staticClass: "content-list"
  }, [_vm._l(_vm.item.content, function (content, index) {
    return [_c('li', {
      key: index
    }, [_c('v-row', {
      attrs: {
        "no-gutters": ""
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('v-sheet', {
      staticClass: "mr-6 mt-6",
      attrs: {
        "color": _vm.item.color,
        "width": "4",
        "height": "4",
        "rounded": "circle"
      }
    })], 1), _c('v-col', [_vm._v(" " + _vm._s(content) + " ")])], 1)], 1)];
  })], 2)])], 1), _c('ul', {
    staticClass: "children-list"
  }, [_vm._l(_vm.item.children, function (child, i) {
    return [_c('li', {
      key: i
    }, [_c('v-sheet', {
      staticClass: "children-title font-gmarket",
      class: `${_vm.item.color}--text`,
      style: `border-color: var(--v-${_vm.item.color}-base)`,
      attrs: {
        "outlined": "",
        "rounded": "pill"
      }
    }, [_vm._v(" " + _vm._s(_vm.index + 1) + "-" + _vm._s(i + 1) + " " + _vm._s(child.title) + " ")]), _c('ul', {
      staticClass: "grandchild-list px-16 px-md-20"
    }, [_vm._l(child.children, function (grandChild, i) {
      return [_c('li', {
        key: i
      }, [_vm._v(" " + _vm._s(grandChild) + " ")])];
    })], 2)], 1)];
  })], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }