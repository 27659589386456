var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', [_vm._l(_vm.people, function (person, index) {
    var _person$thumb;
    return [_c('v-col', {
      key: index,
      attrs: {
        "cols": "6",
        "md": "4"
      }
    }, [_c('v-card', {
      staticClass: "pb-14 pb-md-16",
      attrs: {
        "tile": "",
        "flat": ""
      }
    }, [_c('v-card', {
      attrs: {
        "img": person === null || person === void 0 ? void 0 : (_person$thumb = person.thumb) === null || _person$thumb === void 0 ? void 0 : _person$thumb.src,
        "to": `./${person._id}`,
        "tile": "",
        "flat": "",
        "outlined": ""
      }
    }, [_c('v-responsive', {
      attrs: {
        "aspect-ratio": 384 / 256
      }
    })], 1), _c('v-card-title', {
      staticClass: "tit tit--xs justify-center pa-0 pt-16 pt-md-20"
    }, [_vm._v(" " + _vm._s(person === null || person === void 0 ? void 0 : person.name) + " ")]), _c('div', {
      staticClass: "d-flex justify-center mx-n4 py-12 py-md-16"
    }, [_vm._l(3, function (n) {
      return [_c('div', {
        key: n,
        staticClass: "px-4"
      }, [_c('v-sheet', {
        attrs: {
          "width": "4",
          "height": "4",
          "rounded": "circle",
          "color": "primary"
        }
      })], 1)];
    })], 2), _c('v-card-text', {
      staticClass: "page-text text-center pa-0"
    }, [_vm._v(" " + _vm._s(person === null || person === void 0 ? void 0 : person.summary) + " ")])], 1)], 1)];
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }