var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('sub-visual', {
    attrs: {
      "sh": "소개",
      "tabActive": "사업추진단",
      "text": "금산군에서 나의 자리를 찾다. 금산군 농촌신활력플러스사업을 소개합니다.",
      "image": "/images/about/visual.jpg"
    }
  }), _c('page-section', {
    staticClass: "page-section--first page-section--last"
  }, [_c('v-sheet', {
    staticClass: "grey-background",
    attrs: {
      "color": "grey lighten-5"
    }
  }, [_c('v-img', {
    staticClass: "mr-auto ml-auto mr-xl-0",
    attrs: {
      "max-width": "606",
      "src": "/images/about/team/team.png"
    }
  })], 1), _c('div', {
    staticClass: "mt-40 mt-md-60"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('div', {
    staticClass: "font-size-24 font-size-md-28 font-weight-medium mb-18 mb-md-24"
  }, [_vm._v("금산군 농촌신활력 플러스사업 추진단")]), _c('div', {
    staticClass: "page-text page-text--lg"
  }, [_c('v-row', {
    staticClass: "row--sm grey--text",
    attrs: {
      "align": "center"
    }
  }, [_vm._l(_vm.informations, function (item, index) {
    return [_c('v-col', {
      key: index,
      attrs: {
        "cols": "12"
      }
    }, [_c('v-row', {
      staticClass: "row--sm"
    }, [_c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_vm._v(" " + _vm._s(item.title) + " ")]), _c('v-col', [_vm._v(" " + _vm._s(item.text) + " ")])], 1)], 1)];
  })], 2), _c('div', {
    staticClass: "sns-list mt-18 mt-md-24"
  }, [_c('v-row', {
    staticClass: "row--sm"
  }, [_vm._l(_vm.sns, function (item, index) {
    return [_c('v-col', {
      key: index,
      attrs: {
        "cols": "12"
      }
    }, [_c('a', {
      staticClass: "d-inline-block",
      attrs: {
        "href": item.link,
        "target": "_blank"
      }
    }, [_c('v-row', {
      staticClass: "row--xxs",
      attrs: {
        "align": "center"
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('v-img', {
      attrs: {
        "max-width": "20",
        "src": item.icon
      }
    })], 1), _c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_vm._v(" " + _vm._s(item.title) + " ")])], 1)], 1)])];
  })], 2)], 1)], 1)]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('v-simple-table', {
    staticClass: "v-data-table--no-hover",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function () {
        return [_c('thead', [_c('tr', [_c('th', [_vm._v("직급")]), _c('th', [_vm._v("주요업무")]), _c('th', [_vm._v("전화번호")])])]), _c('tbody', [_vm._l(_vm.organization, function (item, index) {
          return [_c('tr', {
            key: index
          }, [_c('td', [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : item.name) + " ")]), _c('td', {
            staticClass: "text-start"
          }, [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : item.desc) + " ")]), _c('td', [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : item.phone) + " ")])])];
        })], 2)];
      },
      proxy: true
    }])
  })], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }