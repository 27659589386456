<template>
    <v-card color="transparent" tile flat :max-width="maxWidth" class="map-image">
        <img :src="currentMapSrc" alt="" />
        <template v-for="item in maps">
            <!-- <v-btn :key="item.value" :style="`left: calc(${item.x} / 792 * 100%); top: calc(${item.y} / 898 * 100%)`" @mouseenter="changeMap(item.value)" @mouseleave="resetMap"> -->
            <v-btn :key="item.value" :style="`left: calc(${item.x} / 792 * 100%); top: calc(${item.y} / 898 * 100%)`" @click="changeMap(item.value)">
                {{ item.name }}
            </v-btn>
        </template>
    </v-card>
</template>

<script>
import { STORY_REGION_TYPES } from "@/assets/variables";

export default {
    props: {
        maxWidth: { type: String, default: "792" },
        parent: { type: String, default: STORY_REGION_TYPES.GEUMSAN.value },
    },
    data: () => ({
        maps: Object.values(STORY_REGION_TYPES),

        currentMapSrc: "/images/map/map-default.svg",
    }),
    mounted() {
        this.sync();
    },
    methods: {
        changeMap(value) {
            if (value) {
                this.currentMapSrc = `/images/map/${value}.svg`;
                this.emit(value);
            }
        },
        resetMap() {
            this.currentMapSrc = "/images/map/map-default.svg";
        },
        sync() {
            this.currentMapSrc = `/images/map/${this.parent}.svg`;
        },
        emit(value) {
            this.$router.push({ query: { parent: value } });
        },
    },
};
</script>

<style lang="scss" scoped>
.map-image {
    position: relative;
    aspect-ratio: 792/898;
    img {
        display: block;
    }
    .v-btn {
        transform: translate(-25%, -25%);
        position: absolute;
        min-width: initial !important;
        width: calc(120 / 792 * 100%) !important;
        height: calc(100 / 898 * 100%) !important;
        opacity: 0;
        a {
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 100%;
        }
    }
}
</style>