var render = function render(){
  var _vm$resources, _vm$resources$economy, _vm$resources2, _vm$resources2$econom, _vm$resources3, _vm$resources3$facili, _vm$resources4, _vm$resources4$facili, _vm$resources5, _vm$resources5$histor, _vm$resources6, _vm$resources6$histor, _vm$resources7, _vm$resources7$landsc, _vm$resources8, _vm$resources8$landsc, _vm$resources9, _vm$resources9$villag, _vm$resources10, _vm$resources10$villa, _vm$resources11, _vm$resources11$aroun, _vm$resources12, _vm$resources12$aroun;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "line-height-15"
  }, [(_vm$resources = _vm.resources) !== null && _vm$resources !== void 0 && (_vm$resources$economy = _vm$resources.economy) !== null && _vm$resources$economy !== void 0 && _vm$resources$economy.shows ? _c('map-dialog-resource-item', _vm._b({}, 'map-dialog-resource-item', {
    title: '경제자원',
    desc: (_vm$resources2 = _vm.resources) === null || _vm$resources2 === void 0 ? void 0 : (_vm$resources2$econom = _vm$resources2.economy) === null || _vm$resources2$econom === void 0 ? void 0 : _vm$resources2$econom.desc
  }, false)) : _vm._e(), (_vm$resources3 = _vm.resources) !== null && _vm$resources3 !== void 0 && (_vm$resources3$facili = _vm$resources3.facility) !== null && _vm$resources3$facili !== void 0 && _vm$resources3$facili.shows ? _c('map-dialog-resource-item', _vm._b({}, 'map-dialog-resource-item', {
    title: '시설자원',
    desc: (_vm$resources4 = _vm.resources) === null || _vm$resources4 === void 0 ? void 0 : (_vm$resources4$facili = _vm$resources4.facility) === null || _vm$resources4$facili === void 0 ? void 0 : _vm$resources4$facili.desc
  }, false)) : _vm._e(), (_vm$resources5 = _vm.resources) !== null && _vm$resources5 !== void 0 && (_vm$resources5$histor = _vm$resources5.history) !== null && _vm$resources5$histor !== void 0 && _vm$resources5$histor.shows ? _c('map-dialog-resource-item', _vm._b({}, 'map-dialog-resource-item', {
    title: '역사문화자원',
    desc: (_vm$resources6 = _vm.resources) === null || _vm$resources6 === void 0 ? void 0 : (_vm$resources6$histor = _vm$resources6.history) === null || _vm$resources6$histor === void 0 ? void 0 : _vm$resources6$histor.desc
  }, false)) : _vm._e(), (_vm$resources7 = _vm.resources) !== null && _vm$resources7 !== void 0 && (_vm$resources7$landsc = _vm$resources7.landscape) !== null && _vm$resources7$landsc !== void 0 && _vm$resources7$landsc.shows ? _c('map-dialog-resource-item', _vm._b({}, 'map-dialog-resource-item', {
    title: '경관자원',
    desc: (_vm$resources8 = _vm.resources) === null || _vm$resources8 === void 0 ? void 0 : (_vm$resources8$landsc = _vm$resources8.landscape) === null || _vm$resources8$landsc === void 0 ? void 0 : _vm$resources8$landsc.desc
  }, false)) : _vm._e(), (_vm$resources9 = _vm.resources) !== null && _vm$resources9 !== void 0 && (_vm$resources9$villag = _vm$resources9.village) !== null && _vm$resources9$villag !== void 0 && _vm$resources9$villag.shows ? _c('map-dialog-resource-item', _vm._b({}, 'map-dialog-resource-item', {
    title: '마을소유자원',
    desc: (_vm$resources10 = _vm.resources) === null || _vm$resources10 === void 0 ? void 0 : (_vm$resources10$villa = _vm$resources10.village) === null || _vm$resources10$villa === void 0 ? void 0 : _vm$resources10$villa.desc
  }, false)) : _vm._e(), (_vm$resources11 = _vm.resources) !== null && _vm$resources11 !== void 0 && (_vm$resources11$aroun = _vm$resources11.around) !== null && _vm$resources11$aroun !== void 0 && _vm$resources11$aroun.shows ? _c('map-dialog-resource-item', _vm._b({}, 'map-dialog-resource-item', {
    title: '주변연계자원',
    desc: (_vm$resources12 = _vm.resources) === null || _vm$resources12 === void 0 ? void 0 : (_vm$resources12$aroun = _vm$resources12.around) === null || _vm$resources12$aroun === void 0 ? void 0 : _vm$resources12$aroun.desc
  }, false)) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }