var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "tile": "",
      "flat": "",
      "color": "grey lighten-5"
    }
  }, [_c('v-responsive', {
    staticClass: "d-flex justify-center align-center",
    attrs: {
      "aspect-ratio": 1 / 1
    }
  }, [_c('v-img', {
    staticClass: "mx-auto",
    attrs: {
      "max-width": "92",
      "src": _vm.image
    }
  }), _c('div', {
    staticClass: "text-center"
  }, [_c('div', {
    staticClass: "tit tit--xxs text-pre-wrap line-height-1 my-8"
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")]), _c('div', {
    staticClass: "tit tit--sm font-weight-bold line-height-1"
  }, [_vm._v(_vm._s(_vm.number) + _vm._s(_vm.unit))])])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }