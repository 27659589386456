var render = function render(){
  var _vm$population, _vm$population2, _vm$population3, _vm$population4, _vm$population5, _vm$population6;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "4",
      "lg": "2"
    }
  }, [_c('map-dialog-population-item', _vm._b({}, 'map-dialog-population-item', {
    title: '주민수',
    image: '/images/story/community-1.svg',
    number: (_vm$population = _vm.population) === null || _vm$population === void 0 ? void 0 : _vm$population.citizen,
    unit: '명'
  }, false))], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "4",
      "lg": "2"
    }
  }, [_c('map-dialog-population-item', _vm._b({}, 'map-dialog-population-item', {
    title: '가구수',
    image: '/images/story/community-2.svg',
    number: (_vm$population2 = _vm.population) === null || _vm$population2 === void 0 ? void 0 : _vm$population2.household,
    unit: '세대'
  }, false))], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "4",
      "lg": "2"
    }
  }, [_c('map-dialog-population-item', _vm._b({}, 'map-dialog-population-item', {
    title: '아동 · 청소년 수',
    image: '/images/story/community-3.svg',
    number: (_vm$population3 = _vm.population) === null || _vm$population3 === void 0 ? void 0 : _vm$population3.teenager,
    unit: '명'
  }, false))], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "4",
      "lg": "2"
    }
  }, [_c('map-dialog-population-item', _vm._b({}, 'map-dialog-population-item', {
    title: '다문화자녀 수',
    image: '/images/story/community-4.svg',
    number: (_vm$population4 = _vm.population) === null || _vm$population4 === void 0 ? void 0 : _vm$population4.multiCulture,
    unit: '명'
  }, false))], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "4",
      "lg": "2"
    }
  }, [_c('map-dialog-population-item', _vm._b({}, 'map-dialog-population-item', {
    title: '외국인(성인) 수',
    image: '/images/story/community-5.svg',
    number: (_vm$population5 = _vm.population) === null || _vm$population5 === void 0 ? void 0 : _vm$population5.foreigner,
    unit: '명'
  }, false))], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "4",
      "lg": "2"
    }
  }, [_c('map-dialog-population-item', _vm._b({}, 'map-dialog-population-item', {
    title: '최근 5년간\r\n귀농귀촌인 수',
    image: '/images/story/community-6.svg',
    number: (_vm$population6 = _vm.population) === null || _vm$population6 === void 0 ? void 0 : _vm$population6.farmer,
    unit: '명'
  }, false))], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }