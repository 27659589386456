<template>
    <client-page>
        <sub-visual sh="금산에 활력을 불어넣는 사람들" text="금산의 긍정적 변화와 새로운 활력을 이끄는 주민조직 및 활동가를 소개합니다." image="/images/people/visual.jpg" />

        <page-section class="page-section--first">
            <v-row class="ma-n20">
                <v-col cols="12" md="6" class="pa-20">
                    <v-card :img="person?.thumb?.src" tile flat>
                        <v-responsive :aspect-ratio="572 / 382" />
                    </v-card>
                </v-col>
                <v-col cols="12" md="6" class="pa-20">
                    <div class="tit font-weight-bold line-height-1 mb-18 mb-md-24">{{ person?.name }}</div>

                    <ul class="information-list page-text page-text--lg line-height-15">
                        <template v-for="(detail, index) in person?.details">
                            <li :key="index">
                                <v-row no-gutters>
                                    <v-col cols="auto">
                                        <v-sheet width="110" class="font-weight-medium">
                                            {{ detail?.name }}
                                        </v-sheet>
                                    </v-col>
                                    <v-col class="grey--text">
                                        {{ detail?.desc }}
                                    </v-col>
                                </v-row>
                            </li>
                        </template>
                    </ul>
                </v-col>
            </v-row>
        </page-section>

        <page-section class="pt-0 page-section--last">
            <div class="tit font-weight-bold line-height-1">{{ title }}</div>
            <v-divider class="v-divider--dark my-18 my-md-24" />

            <people-view-slide v-bind="{ images: person?.images }" v-if="!isGroup"/>
            <people-view-list v-else />
        </page-section>
    </client-page>
</template>

<script>
import api from "@/api";
import { PEOPLE_TYPES } from "@/assets/variables";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import PageSection from "@/components/client/sub/page-section.vue";
import PeopleViewSlide from "@/components/client/people/people-view-slide.vue";
import PeopleViewList from "@/components/client/people/people-view-list.vue";

export default {
    components: {
        SubVisual,
        ClientPage,
        PageSection,
        PeopleViewSlide,
        PeopleViewList,
    },
    data: () => ({
        person: {},
    }),
    computed: {
        _people() {
            return this.$route.params._people;
        },
        isGroup() {
            return this.person.type === PEOPLE_TYPES.GROUP.value;
        },
        title() {
            return this.isGroup ? "구성팀 소개" : "활동 사진";
        }
    },
    mounted() {
        this.search();
    },
    methods: {
        async search() {
            try {
                let { person } = await api.v1.people.get({ _id: this._people });
                this.person = person;
            } catch (error) {
                this.$handleError(error);
            }
        },
    },
    watch: {
        _people() {
            this.search();
        },
    },
};
</script>

<style lang="scss" scoped>
.information-list {
    border-top: 1px solid #000;
    padding-top: 18px;
    > li {
        padding-bottom: 16px;
        &:last-child {
            padding-bottom: 0;
        }
    }
}
@media (min-width: 768px) {
    .information-list {
        padding-top: 24px;
        > li {
            padding-bottom: 20px;
        }
    }
}
</style>
