var render = function render(){
  var _vm$terms, _vm$terms2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return (_vm$terms = _vm.terms) !== null && _vm$terms !== void 0 && _vm$terms.content ? _c('div', [_c('p', {
    staticClass: "page-text page-text--sm grey--text",
    domProps: {
      "innerHTML": _vm._s((_vm$terms2 = _vm.terms) === null || _vm$terms2 === void 0 ? void 0 : _vm$terms2.content)
    }
  })]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }