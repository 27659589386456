<template>
    <client-page>
        <sub-visual sh="아카이빙" tabActive="연혁" text="금산군에 있는 260개 마을의 발전을 위한 기초자료를 구축하고자 마을자원과 현황을 조사하고, 주민의 사업수요를 들어보았습니다." image="/images/archiving/visual.jpg" />

        <page-section title="연혁" subtitle="History" class="page-section--first page-section--last">
            <ul class="history">
                <template v-for="(item, index) in histories">
                    <li :key="index">
                        <v-row class="row--xl">
                            <v-col cols="12" md="3">
                                <div class="history__year tit tit--lg">
                                    {{ item.year }}
                                </div>
                            </v-col>
                            <v-col cols="12" md="9" class="page-text">
                                <ul class="history__child">
                                    <template v-for="(child, index) in item.children">
                                        <li :key="index">
                                            <v-row class="row--xs">
                                                <v-col cols="auto">
                                                    <span class="history__month">
                                                        {{ child.month }}
                                                    </span>
                                                </v-col>
                                                <v-col class="grey--text line-height-15">
                                                    <ul class="history__content">
                                                        <template v-for="(grandChild, index) in child.children">
                                                            <li :key="index">
                                                                <v-row class="ma-n2">
                                                                    <v-col cols="auto" class="pa-2">·</v-col>
                                                                    <v-col class="pa-2">
                                                                        {{ grandChild }}
                                                                    </v-col>
                                                                </v-row>
                                                            </li>
                                                        </template>
                                                    </ul>
                                                </v-col>
                                            </v-row>
                                        </li>
                                    </template>
                                </ul>
                            </v-col>
                        </v-row>
                    </li>
                </template>
            </ul>
        </page-section>
    </client-page>
</template>

<script>
import api from "@/api";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import PageSection from "@/components/client/sub/page-section.vue";

export default {
    components: {
        SubVisual,
        ClientPage,
        PageSection,
    },
    data: () => ({
        histories: [],
    }),
    mounted() {
        this.search();
    },
    methods: {
        async search() {
            try {
                let { summary, histories } = await api.v1.archiving.history.gets({});
                this.histories = this.setHistories(histories);
            } catch (error) {
                this.$handleError(error);
            }
        },
        setHistories(histories) {
            let result = histories.reduce((acc, { year, month, content, index, shows }) => {
                if (!shows) return acc;

                const yearStr = year.toString();
                const monthStr = month.toString().padStart(2, "0");

                let yearObj = acc.find((y) => y.year === yearStr);
                if (!yearObj) {
                    yearObj = { year: yearStr, children: [] };
                    acc.push(yearObj);
                }

                let monthObj = yearObj.children.find((m) => m.month === monthStr);
                if (!monthObj) {
                    monthObj = { month: monthStr, children: [] };
                    yearObj.children.push(monthObj);
                }

                monthObj.children.push({ index, content });
                return acc;
            }, []);

            return result
                .sort((a, b) => b.year - a.year)
                .map((yearObj) => ({
                    ...yearObj,
                    children: yearObj.children
                        .sort((a, b) => b.month - a.month)
                        .map((monthObj) => ({
                            ...monthObj,
                            children: monthObj.children.sort((a, b) => b.index - a.index).map(({ content }) => content),
                        })),
                }));
        },
    },
};
</script>

<style lang="scss" scoped>
.v-divider {
    &--dark {
        border-color: var(--v-grey-darken4);
    }
}
.history {
    > li {
        padding-bottom: 60px;
        &:last-child {
            padding-bottom: 0;
        }
    }
    &__year {
        font-weight: 700;
        color: var(--v-secondary-base);
        line-height: 1;
        padding-top: 16px;
        border-top: 2px solid currentColor;
    }
    &__month {
        font-weight: 500;
    }
    &__child {
        border-top: 1px solid var(--v-grey-lighten4);
        > li {
            padding: 20px 0;
            border-bottom: 1px solid var(--v-grey-lighten4);
        }
    }
    &__content {
        > li {
            margin-bottom: 6px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}
@media (min-width: 768px) {
    .history {
        > li {
            padding-bottom: 80px;
            &:last-child {
                margin-bottom: 0;
            }
        }
        &__year {
            padding-top: 20px;
        }
        &__content {
            > li {
                margin-bottom: 8px;
            }
        }
    }
}
</style>
